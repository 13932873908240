import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import ChooseAccount from "../../components/auth/ChooseAccount";

const IMAGE_QUERY = graphql`
  query {
    file(relativePath: { eq: "signup.jpg" }) {
      ...fluidImage
    }
  }
`;

const SignupPage = () => {
  const image = useStaticQuery(IMAGE_QUERY).file.childImageSharp.gatsbyImageData;

  return <ChooseAccount image={image} signUp seoTitle="Sign Up" />;
};

export default SignupPage;
